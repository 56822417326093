import {
  defineGetServerData
} from "./chunk.FD6A6SJ5.js";
import {
  Server
} from "./chunk.WLUKVG5Y.js";

// universal_demo.tsx?intercept
import React2 from "react";

// src/pages/demo/index.tsx
import React from "react";
import { Link } from "react-router-dom";
var App = (props) => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h1", null, "Emojis"), /* @__PURE__ */ React.createElement(Link, {
    to: "/"
  }, "go back"), /* @__PURE__ */ React.createElement("ul", {
    className: "emoji-list"
  }, props.emojis.map((emoji) => /* @__PURE__ */ React.createElement("li", {
    className: "emoji-item",
    key: emoji.name,
    dangerouslySetInnerHTML: { __html: emoji.htmlCode[0] }
  }))));
};
var demo_default = App;

// src/pages/demo/prefetch.ts
var prefetch_default = defineGetServerData(async () => {
  const resp = await fetch("https://emoji-teal.vercel.app/api/emoji");
  const res = await resp.json();
  return {
    emojis: res
  };
});

// universal_demo.tsx?intercept
var Component = (props) => {
  return /* @__PURE__ */ React2.createElement(Server, {
    ...props
  }, /* @__PURE__ */ React2.createElement(demo_default, null));
};
export {
  Component,
  prefetch_default as getServerSideProps
};
