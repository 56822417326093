import { defineGetServerData } from '@speedy-js/universal/types';

export default defineGetServerData(async () => {
  const resp = await fetch('https://emoji-teal.vercel.app/api/emoji');
  const res = await resp.json();

  return {
    emojis: res,
  };
});
